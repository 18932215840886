import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {
    url: "womens-health-impact/hpv-std-screening",
    handle: "HPV & STD Screening",
  },
]

const HpvScreening = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "history.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="HPV Screening" />
          <SubpageBanner image={background} heading="HPV &amp; STD Screening" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <h2 style={{marginTop: "0"}}>HPV Screening</h2>
                <SimpleText>
                  <p>HPV is the most common sexually transmitted infection (STI). According to the Centers for Disease Control (CDC), 79 million Americans are infected with HPV. There are many different types of HPV, and some types can cause health problems including genital warts and cancers.</p>
                  <p>HPV can cause cervical and other cancers. Diseases, including cancers, resulting from HPV infection can progress quickly or take years, even decades, to develop after a person is infected with the HPV virus. Because of this, effective regular screening that can enable early detection is essential.</p>
                  <p>Having a more complete sample can aid in ensuring that targeted cells for HPV testing, which often come from the cytobrush sample, are not only available but present in sufficient quantity for testing. In a nationwide average of ~6% of cases, there are not enough cells available in the sample to complete both Pap testing and HPV testing. This QNS (Quantity Not Sufficient) rate can be much lower or eliminated if a more complete sample is captured from the cytobrush. This can enable the lab to provide all testing and information the clinician needs in caring for the patient.</p>
                </SimpleText>
                <h2>Screening for other STDs</h2>
                <SimpleText>
                  <p>Cases of confirmed STDs are up across almost every category. According to the CDC in 2018, there were 1.8 million cases of chlamydia; a 19% increase since 2014. The 583,405 cases of gonorrhea mark a 63% increase since 2014.</p>
                  <p>By using the exCellerator as a compliment to the ThinPrep Pap Test system, clinicians now have an opportunity to capture a more complete sample from the cytobrush. This more complete sample can include endocervical cells; an important component in ancillary testing such as HPV, Chlamydia, and Gonorrhea.</p>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default HpvScreening
